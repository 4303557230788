<template>
  <div class="box">
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">通用题目导入</span>
    </div>
    <div class="PageContain">
      <span
        class="tab-btn"
        v-for="(item, index) in ['员工岗位通用题目', '部门负责人']"
        :key="index"
        :class="[index == tabIndex ? 'tabBtnActive' : null]"
        @click="changeTab(index)"
      >
        {{ item }}
      </span>
      <div class="btn">
        <el-button type="success" class="but">
          导入 Excell表格<input
            type="file"
            class="int"
            style="width: 100px"
            @change="choosefile"
          />
        </el-button>
        <el-button type="primary" @click="save" class="save"
          >保存提交</el-button
        >
      </div>
      <div class="_Table">
        <el-table
          v-if="tabIndex == 0"
          :data="excelldata1"
          stripe
          :height="elementHeight"
          style="width: 100%"
          :border="true"
          :fit="true"
          class="tab"
          id="table"
        >
          <el-table-column prop="question" label="题目" align="center">
          </el-table-column>
          <el-table-column prop="answer" label="检查内容" align="center">
          </el-table-column>
          <el-table-column prop="auditMethod" label="检查方式" align="center">
          </el-table-column>
          <el-table-column prop="" label="结果" align="center">
          </el-table-column>
          <el-table-column prop="" label="记录" align="center">
          </el-table-column>
        </el-table>
        <el-table
          v-if="tabIndex == 1"
          :data="excelldata2"
          stripe
          :height="elementHeight"
          style="width: 100%"
          :border="true"
          :fit="true"
          class="tab"
          id="table"
        >
          <el-table-column prop="question" label="题目" align="center">
          </el-table-column>
          <el-table-column prop="answer" label="答案" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          class="_Pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tabIndex == 0 ? excelldata1.length : excelldata2.length"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import { post } from '../../api/http'

export default {
  components: {},
  data() {
    return {
      excelldata1: [],
      excelldata2: [],
      start: 0,
      end: 10,
      currentPage: 1,
      curSize: 10,
      tabIndex: 0,
      elementHeight: 0,
    }
  },
  mounted() {
    this.getElementHeight()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 70 + 100)
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    // 导入功能
    choosefile(e) {
      const ts = this
      let files = e.target.files
      const fileReadeer = new FileReader()
      fileReadeer.onload = function (e) {
        try {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const one = workbook.Sheets
          const listNew = XLSX.utils.sheet_to_json(one.Sheet1)
          var stopExecution = false
          listNew.map((item) => {
            if (stopExecution) return
            if (
              (item['检查方式'] && item['检查内容'] && ts.tabIndex == 1) ||
              (!item['检查方式'] && !item['检查内容'] && ts.tabIndex == 0)
            ) {
              ts.$message.error('格式不正确')
              return (stopExecution = true)
            }
            if (stopExecution) return
            if (ts.tabIndex == 0) {
              ts.excelldata1.push({
                fileNo: 'YGTY01',
                fileName: '员工通用题',
                question: item['题目'],
                auditMethod: item['检查方式'],
                answer: item['检查内容'],
              })
            } else {
              ts.excelldata2.push({
                fileNo: 'YGTY02',
                fileName: '部门负责人通用题',
                question: item['题目'],
                answer: item['答案'],
              })
            }
          })
        } catch (e) {
          console.log('文件类型不正确')
          return
        }
      }
      fileReadeer.readAsBinaryString(files[0])
    },
    // 保存功能
    save() {
      if (this.tabIndex == 0 ? this.excelldata1.length : this.excelldata2.length ) {
        post(
          '/api/QuestionGeneric/BatchYGAdd',
          this.tabIndex == 0 ? this.excelldata1 : this.excelldata2
        )
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '保存成功',
              })
            } else {
              this.$message({
                type: 'warning',
                message: res.message,
              })
            }
          })
          .catch((err) => {
            console.log(err)
            this.$message({
              type: 'error',
              message: err.message + '保存失败，格式不正确或者请重新登录',
            })
          })
      } 
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style lang="less" scoped>
.tab-btn {
  display: inline-block;
  text-align: center;
  padding: 15px 0;
  display: inline-block;
  margin: 15px 20px 0px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #818080;
}
.tabBtnActive {
  border-bottom: 2px solid #409eff !important;
  color: #409eff !important;
}
.but {
  width: 100px;
  position: relative;
  .int {
    position: absolute;
    left: 0%;
    top: 0%;
    opacity: 0;
    background: red;
    width: 300px;
    height: 39px;
  }
}
.tab {
  margin: 10px auto;
}

.btn {
  display: flex;
  justify-content: end;
  margin-right: 20px;
  border-top: 1px solid rgb(214, 213, 213);
  padding-top: 15px;
  .save {
    width: 100px;
  }
}
</style>
